import React from "react"
import { navigate } from "gatsby"

import { Box, Flex } from "theme-ui"
import Button from "../components/base/buttons"
import Text from "../components/base/text/text"

const ErrorPage = () => {
  return (
    <Flex sx={{ justifyContent: "center", alignItems: "center", flex: "1" }}>
      <Box>
        <Text>
          {`Sorry, we can't seem to find the page that you're looking for. Click
          below to get back on the court.`}
        </Text>
        <Flex sx={{ width: "100%", justifyContent: "center" }} mt="2">
          <Button
            onClick={() => navigate(`/`)}
            variant="primary"
            sx={{ padding: "1em", fontSize: 0 }}
          >
            <Text>Go back</Text>
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

export default ErrorPage
